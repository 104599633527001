<template>
    <div class="content">
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line">
        <div class="nav">
            <span style="color:rgba(51, 51, 51, .6)">首页</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span style="color:rgba(51, 51, 51, .6)">关于我们</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span>华版数字版权服务中心（CACC平台）简介</span>
        </div>
        <div class="title">华版数字版权服务中心（CACC平台）简介</div>
        <div class="blurb">
            <div class="left">
                <img src="../../assets/img/index/blurb-logo.png" alt="" class="blurb-logo">
            </div>
            <div class="right">
                <div>平台名称 ：华版数字版权服务中心</div>
                <div>服务宗旨 ：数字版权一站式综合服务</div>
                <div>成立时间 ：2020年</div>
                <div>总部地点 ：中国版权保护中心B座4层</div>
            </div>
        </div>
        <div class="one">
            <div class="sub-title">
                <div class="name">平台介绍</div>
                <div class="enname">INTRODUCTION TO THE PLATFORM</div>
            </div>
            <div class="info">
                华版数字版权服务中心以打通数字版权创造、运用、保护、管理、服务全链条为目标，以满足创新主体和市场主体服务需求为导向，以加快推动版权数字化服务业高质量发展为己任，全面建设华版数字CACC综合服务平台，并于2021年5月23日上线运行。平台致力于基于DCI数字版权保护体系来发展数字版权服务，通过区块链、大数据、云计算、AI等先进技术支撑，打造面向全球集版权登记、存证、软著、侵权监测、取证、维权、交易、分发、结算于一体的一站式数字版权综合服务平台-“华版数字版权综合服务平台”，为版权人提供便捷、专业的数字版权服务，平台业务包括数字版权登记、数字版权存证、数字版权交易、侵权监测、取证维权、版权知识学院等。可以满足版权人的全部需求。保护版权人的合法权益、助其实现作品的版权价值。已累计服务数百万用户。
            </div>
        </div>
        <div class="two">
            <div class="sub-title">
                <div class="name">产品与服务</div>
                <div class="enname">PRODUCTS & SERVICES</div>
            </div>
            <div class="info">
                <div class="info-item" v-for="(item,index) in oneList">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="jj-right"></div>
                        <div class="name">{{item.name}}</div>
                    </div>
                    <div class="right">
                        {{item.info}}
                    </div> 
                </div>
            </div>
        </div>
        <div class="three">
            <div class="sub-title">
                <div class="name">版权资产交易</div>
                <div class="enname">TRADING MODE</div>
            </div>
            <div class="info">
                <div>区块链技术加DCI数字版权保护体系，多种技术手段结合保障业务可以便捷有序、安全透明、高效专业的进行，为“版权需求者”和“版权拥有者”供需双方搭建平台，让双方更快速的寻找到对方，打破信息不对等的窘况。</div>
                <div>海量正版，满足你所需；一键交易，助你快速变现。担保交易0风险，版权收入秒提现。7*24小时线上随时自主进行操作。</div>

            </div>
        </div>
        <div class="four">
            <div class="sub-title">
                <div class="name">核心价值</div>
                <div class="enname">CORE VALUES</div>
            </div>
            <div class="info">
                <div class="info-item" v-for="(item,index) in fourList">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="jj-right"></div>
                        <div class="name">{{item.name}}</div>
                    </div>
                    <div class="right">
                        {{item.info}}
                    </div> 
                </div>
            </div>
        </div>
        <div class="five">
            <div class="sub-title">
                <div class="name">平台文化</div>
                <div class="enname">PLATFORM CULTURE</div>
            </div>
            <div class="info">
                <div class="info-item" v-for="(item,index) in fiveList">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="jj-right"></div>
                        <div class="name">{{item.name}}</div>
                    </div>
                    <div class="right">
                        {{item.info}}
                    </div> 
                </div>
            </div>
        </div>
        <div class="six">
            <div class="sub-title">
                <div class="name">大事件</div>
                <div class="enname">BIG EVENT</div>
            </div>
            <div class="info">
                <div class="info-item1">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="name">2023</div>
                    </div>
                    <div class="right">
                        
                        <div class="r-left"></div>
                        <div class="r-right"></div>
                        <div class="r-content">
                            <ul>
                                <li>
                                    2023年2月10日 华版数字与贵州中云版权科技有限公司签订战略合作协议
                                </li>
                                <li>
                                    2023年3月31日 华版数字与山海数字（上海）文化科技有限公司签订战略合作协议
                                </li>
                                <li>
                                    2023年4月19日 华版数字与北京文创板发展有限公司签订战略合作协议
                                </li>
                                
                            </ul>
                        </div>
                    </div> 
                </div>
                <div class="info-item1">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="name">2022</div>
                    </div>
                    <div class="right">
                        
                        <div class="r-left"></div>
                        <div class="r-right"></div>
                        <div class="r-content">
                            <ul>
                                <li>
                                    2022年6月24日 华版数字与中国技术交易所签订战略合作协议
                                </li>
                                <li>
                                    2022年10月28日 华版数字与中创文贸（北京）文物艺术品有限公司签订中国文保链可信存证版权保护平台合作协议                               
                                </li>
                                
                            </ul>
                        </div>
                    </div> 
                </div>
                <div class="info-item1">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="name">2021</div>
                    </div>
                    <div class="right">
                        
                        <div class="r-left"></div>
                        <div class="r-right"></div>
                        <div class="r-content">
                            <ul>
                                <li>
                                    2021年6月9日， 华版数字与永新华韵文化产业有限公司达成战略合作，在全国范围内为非物质文化遗产提供版权服务。
                                </li>
                                <li>
                                    2021年5月23日，华版数字版权服务平台开发完成并上线。                               
                                </li>
                                <li>2021年4月15日，华版数字版权服务平台与北方工业大学联合共建了数字版权服务技术应用研究联合实验室，校企携手 推进数字版权技术应用转化。</li>
                                <li>2021年3月30日，华版和中国电影出版社有限公司在天桥艺术大厦举行战略合作协议签署仪式。</li>
                                
                            </ul>
                        </div>
                    </div> 
                </div>
                <div class="info-item2">
                    <div class="left">
                        <div class="jj-left"></div>
                        <div class="name">2020</div>
                    </div>
                    <div class="right">
                        
                        <div class="r-left"></div>
                        <div class="r-right"></div>
                        <div class="r-content">
                            <ul>
                                <li>
                                    2020年11月，华版成为新闻出版行业标准《基于区块链的版权保护服务技术要求》起草组组长单位。
                                </li>
                                <li>
                                    2020年9月14日，华版与中国教育电视台播控中心达成战略合作。                               
                                </li>
                                <li>2020年9月14日，华版与北京晋江原创网络科技有限公司达成战略合作。</li>
                                <li>2020年9月14日，华版数字版权服务平台与DCI技术研究与应用联合实验室达成战略合作。</li>
                                <li>2020年9月14日，华版与中国广电6G融合一体化平台达成战略合作。</li>
                                <li>2020年9月14日，华版与中新金桥数字科技（北京）有限公司达成战略合作。</li>
                                <li>2020年9月14日，华版与俄罗斯联邦友谊基金会达成战略合作。</li>
                                <li>2020年9月，华版数字版权服务中心股份有限公司获得中国版权保护中心发起的国家项目CACC平台的独家运营权，成为此项目唯一运营主体，华版数字版权服务平台开始商业化运营。</li>
                                <li>2020年7月21日，华版数字版权服务平台隶属的华版数字版权服务中心股份有限公司于北京市宣布注册成立。</li>
                                
                            </ul>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
    </div>
</template>
  
<script>
  export default {
    name: "Trends",
    components: {

    },
    data() {
      return {
        oneList:[
            {
                name:'数字版权登记',
                info:'版权登记涉及著作权法第三条所列的作品，如软件、文字作品、口述作品、美术摄影作品、影视作品、音乐作品，录像作品、工程和产品设计图纸及其说明等。华版数字版权综合服务平台避开了传统线下登记的弊端，全部流程从线下迁移到线上，大大缩短了审核时限、提升了效率、降低了成本，用户可全程进行线上跟踪，实时了解登记进度。'
            },
            {
                name:'数字版权存证',
                info:'用于版权“快速确权”。由区块链共识机制将加密数据打包进行存储；通过可信、中心化服务实现数据加密；同时平台会结合签名+时间戳+区块链多种手段，形成不可篡改、不可伪造的证据信息。并保证数字版权存证可溯源性，平台支持使用唯一用户ID将同一存证的多个历史存储版本结合起来，同时，用户可以在平台中查看已存证服务的详细内容，使数字版权存证业务具有持续性、可追溯性及完整性。'
            },
            {
                name:'数字版权交易',
                info:'版权交易指作品版权中全部或部分经济权利，通过版权许可或版权转让的方式，以获取相应经济收入的交易行为，区别于传统版权交易的弊端，数字版权交易为“版权需求者”和“版权拥有者”供需双方搭建平台，让双方更快速的寻找到对方，打破信息不对等的窘况，同时，保证交易的公开与透明，保障交易双方的合法权益及利益不受损害，并可随时查询交易进度。'
            },
            {
                name:'数字版权维权服务',
                info:'数字版权维权服务是由侵权监测、线上取证、诉讼维权所构成的一体化维权服务体系，可极大缩短维权周期与成本。结合多种高科技技术手段，实现对版权方作品内容的全网监测服务，在全网监测过程中，若发现对版权方有侵权行为的企业或个人，我们将及时进行线上取证并进行证据固化，固证资料将在后续的维权服务中，作为有效电子证据线上移交互联网法院，并提交维权诉求，达成维权目的，全程可线上跟踪庭审过程与结果。'
            },
            {
                name:'版权商学院',
                info:'由数字版权行业专家对版权权利人进行版权专业知识的培训，帮助版权权利人更好地实现版权的价值。'
            },
            
        ],
        fourList:[
            {
                name:'连接价值',
                info:'数字版权一体化服务平台，为“版权需求者”和“版权拥有者”供需双方搭建起便捷的桥梁，全程线上化操作，更公正、更透明、更安全、更流畅。'
            },
            {
                name:'行业价值',
                info:'华版CACC数字版权服务平台具有五大基本功能：区块链可信存证、数字版权登记、数权服务、数字版权诉讼维权和版权交易，一站式解决了传统版权确权难、固证难、维权难、变现难等四大难点，数据秒级上链不可篡改，实现版权归属明确和证据固化，规避了传统版权登记弊端，在线发放国家版权局认证的版权登记证书，流程自动化，更方便用户查询，证据链可作为互联网法院在线案件审理的有效电子证据，促进社会进步，实现版权的最大商业价值。'
            },
            {
                name:'数据价值',
                info:'平台汇聚了大量的版权权利人及其海量的不同形式的作品，满足不同需求方的多种版权需求。'
            },
            {
                name:'社会价值',
                info:'应知识产权强国建设的伟大号召，为广大版权权利人的价值实现保驾护航。提升全民版权意识，强化版权管理，优化版权生态。为提升数字版权服务水平以及中国数字经济的崛起贡献一份力量，为经济社会高质量发展保驾护航。'
            }
            
        ],
        fiveList:[
            {
                name:'定位',
                info:'数字版权行业全球领先的国家级一站式综合服务平台。'
            },
            {
                name:'使命',
                info:'帮版权的个体利益回归，助创新的社会价值实现。'
            },
            {
                name:'愿景',
                info:'共创、共建，包括版权的持有者、使用者、分发者、交易者、监管者等在内的开放式数字版权生态系统。价值观：连接、开放；共生、共享；可信、可用。'
            }
            
        ]
      };
    },
    mounted() {
      
    },
    methods: {
        
    }
  };
</script>

<style scoped lang="scss">
.content{
    width: 1400px;
    margin: 0 auto;
    position: relative;
    .top-line{
        width: 100%;
        height: 85px;
        margin-top: 15px;
    }
    .nav{
        width: 100%;
        height: 85px;
        font-size: 16px;
        font-family: SimSun;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 20px;
    }
    .title{
        font-size: 24px;
        font-family: FZCuSong-B09;
        font-weight: 500;
        color: #333333;
        text-align: center;
    }
    .blurb{
        margin-top: 25px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .left{
            width: 340px;
            height: 326px;
            background-image: url('../../assets/img/index/blurb-bg.png');
            background-repeat: no-repeat;
            background-size: 100%;
            position: relative;
            .blurb-logo{
                width: 198px;
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                // height: 198px;
            }
        }
        .right{
            font-size: 20px;
            font-family: FZCuSong-B09;
            font-weight: 400;
            color: #333333;
            line-height: 61px;
            margin-left: 63px;
        }

    }
    .sub-title{
        width: 334px;
        height: 88px;
        background-image: url('../../assets/img/index/hz-title.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 auto 40px auto;
        .name{
            font-size: 30px;
            font-family: FZLiShu-S01S;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 95px;
            text-indent:2em;
        }
        .enname{
            // text-indent:3.4em;
            font-size: 16px;
            font-family: FZHuaLi-M14;
            font-weight: 400;
            color: #333333;
            opacity: 0.5;
            margin-top: -20px;
        }
    }
    .one{
        margin-top: 42px;
        .info{
            text-indent:2em;
            font-size: 20px;
            font-family: SimSun;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
        }
    }
    .two,.four{
        margin-top: 50px;
        .enname{
            text-indent:3.4em;
        }
        .info{
            .info-item{
                margin-bottom: 30px;
                display: flex;
                flex-wrap: nowrap;
                
                .left{
                    width: 179px;
                    height: 51px;
                    position:relative;
                    .name{
                        width: 179px;
                        height: 51px;
                        font-size: 20px;
                        font-family: FZCuSong-B09;
                        font-weight: 400;
                        color: #000000;
                        text-align: center;
                        line-height: 51px;
                        position:absolute;
                        left: 0;
                        top: 0;
                    }
                    .jj-left{
                        width: 179px;
                        height: 51px;
                        background-image:url('../../assets/img/index/jj-left.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                    }
                    .jj-right{
                        width: 162px;
                        height: 50px;
                        background-image:url('../../assets/img/index/jj-right.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
                .right{
                    line-height: 30px;
                    width: calc( 100% - 220px);
                    font-size: 20px;
                    font-family: SimSun;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 32px;
                    margin-top: 12px;
                }
            }
        }
    }
    .three{
        margin-top: 42px;
        .name{
            text-indent:1.6em;
        }
        .enname{
            text-indent:3.4em;
        }
        .info{
            
            font-size: 20px;
            font-family: SimSun;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            div{
                text-indent:2em;
            }
        }
    }

    .five{
        margin-top: 50px;
        .enname{
            text-indent:3.4em;
        }
        .info{
            .info-item{
                margin-bottom: 30px;
                display: flex;
                flex-wrap: nowrap;
                
                .left{
                    width: 108px;
                    height: 50px;
                    position:relative;
                    .name{
                        width: 108px;
                        height: 50px;
                        font-size: 20px;
                        font-family: FZCuSong-B09;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 40px;
                        position:absolute;
                        left: 0;
                        top: 0;
                    }
                    .jj-left{
                        width: 108px;
                        height: 50px;
                        background-image:url('../../assets/img/index/wh-bg.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    
                }
                .right{
                    line-height: 30px;
                    width: calc( 100% - 220px);
                    font-size: 20px;
                    font-family: SimSun;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 32px;
                    margin-top: 12px;
                }
            }
        }
    }
    .six{
        margin-top: 50px;
        height: 1800px;
        .enname{
            text-indent:3.4em;
        }
        .info{
            .info-item1{
                display: flex;
                flex-wrap: nowrap;
                margin-bottom: 80px;
                .left{
                    width: 108px;
                    height: 50px;
                    position:relative;
                    margin-top: 20px;
                    .name{
                        width: 108px;
                        height: 50px;
                        font-size: 20px;
                        font-family: FZCuSong-B09;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 40px;
                        position:absolute;
                        left: 0;
                        top: 0;
                    }
                    .jj-left{
                        width: 108px;
                        height: 50px;
                        background-image:url('../../assets/img/index/zp-bg.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    
                }
                .right{
                    width: calc(100% - 108px);
                    height:330px;
                    position: relative;
                    margin-left: 20px;
                    .r-left{
                        width: 1154px;
                        height: 315px;
                        background-image:url('../../assets/img/index/sj-left.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        // left: 10px;
                        // bottom: 0;
                    }
                    .r-right{
                        width: 972px;
                        height: 259px;
                        background-image:url('../../assets/img/index/sj-right.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        right: 10px;
                        bottom: 0;
                    }
                    ul{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        padding: 50px 70px;
                        li{
                            font-size: 20px;
                            font-family: SimSun;
                            font-weight: 400;
                            color: #333333;
                            line-height: 30px;
                        }
                        li::marker{
                            color: #9D1610;
                        }
                    }
                }
            }
            .info-item2{
                display: flex;
                flex-wrap: nowrap;
                
                .left{
                    width: 108px;
                    height: 50px;
                    position:relative;
                    margin-top: 20px;
                    .name{
                        width: 108px;
                        height: 50px;
                        font-size: 20px;
                        font-family: FZCuSong-B09;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 40px;
                        position:absolute;
                        left: 0;
                        top: 0;
                    }
                    .jj-left{
                        width: 108px;
                        height: 50px;
                        background-image:url('../../assets/img/index/zp-bg.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    
                }
                .right{
                    width: calc(100% - 108px);
                    height:460px;
                    position: relative;
                    margin-left: 20px;
                    .r-left{
                        width: 1154px;
                        height: 315px;
                        background-image:url('../../assets/img/index/sj-left.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        // left: 10px;
                        // bottom: 0;
                    }
                    .r-right{
                        width: 972px;
                        height: 259px;
                        background-image:url('../../assets/img/index/sj-right.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        right: 10px;
                        bottom: 0;
                    }
                    ul{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        padding: 50px 70px;
                        li{
                            font-size: 20px;
                            font-family: SimSun;
                            font-weight: 400;
                            color: #333333;
                            line-height: 30px;
                        }
                        li::marker{
                            color: #9D1610;
                        }
                    }
                }
            }
        }
    }
}

</style>
  
  